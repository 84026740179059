export const splitArray = inputArray => {
  const pageSize = 10;

  const pages = Math.floor(inputArray.length / pageSize);
  const finalArr = [];
  for (let i = 0; i <= pages; i++) {
    finalArr.push(inputArray.slice(i * pageSize, (i + 1) * pageSize));
  }
  return [finalArr, pageSize];
};
