import { useEffect, useState, useCallback } from "react";

const useTabState = () => {
  const [isActive, setUsActive] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    setUsActive(document.visibilityState === "visible");
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line
  }, []);

  return { isActive };
};

export default useTabState;
