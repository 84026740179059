import React from "react";

const ProgressBar = ({ bgcolor, progress, height, progressText }) => {
  const Parentdiv = {
    height: height
  };

  const Childdiv = {
    width: `${progress}%`,
    backgroundColor: bgcolor
  };

  const textDiv = {
    fontSize: height - 2
  };

  return (
    <div className="progress-bar" style={Parentdiv}>
      <div className="progress-bar-child" style={Childdiv}>
        <span className="progress-bar-text" style={textDiv}>
          {progressText ? progressText : `${progress}%`}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
