import React, { useState } from "react";
import Input from "../components/Input";
import APICaller from "../utils/APICaller";

export default function SendMessage() {
  const [thumbUrl, setThumbUrl] = useState("");
  const [textMessage, setTextMessage] = useState("..");
  const [channelList, setChannelList] = useState("[]");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      text: textMessage,
      thumbUrl,
      channels: channelList
    };
    const { data } = await APICaller({
      method: "POST",
      reqUrl: "/api/v1/task/sendMessages",
      data: body
    });
    setLoading(false);
    console.log("SendMessage response===", data);
  };

  return (
    <main>
      <div className="content">
        <h1>Send message to chats</h1>
        <form>
          <Input id="thumbUrl" name="thumbUrl" label="ThumbUrl" value={thumbUrl} onChange={setThumbUrl} />

          <textarea
            style={{ width: "100%" }}
            id="textMessage"
            rows="5"
            name="textMessage"
            label="Text message"
            value={textMessage}
            onChange={e => setTextMessage(e.target.value)}
          />
          <textarea
            style={{ width: "100%" }}
            id="channelList"
            rows="20"
            name="channelList"
            label=""
            value={channelList}
            onChange={e => setChannelList(e.target.value)}
          />
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
          >
            <div className="d-flex align-items-center">
              <button disabled={loading} className={`btn primary`} type="submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      {loading && (
        <div className="d-flex loading-box">
          <div className="loading-div"></div>
          <div>Loading...</div>
        </div>
      )}
    </main>
  );
}
