import React, { useEffect, useState } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useParams } from "react-router-dom";

import Input from "../components/Input";
import APICaller from "../utils/APICaller";

export default function SendMessage() {
  const { ch = "" } = useParams();
  console.log("urlParams.search", ch);
  const [channelName, setchannelName] = useState(ch);
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState({});

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    const { data } = await APICaller({
      method: "GET",
      reqUrl: "/api/v1/task/getFullChat",
      data: { channel: channelName.replace("https://t.me/", "") }
    });
    setLoading(false);
    console.log("SendMessage response===", data);
    const filteredfullChat = {};
    if (!isEmpty(data)) {
      filteredfullChat["name"] = get(data, "result.chats[0].title", "");
      filteredfullChat["pts"] = get(data, "result.fullChat.pts", 0);
      filteredfullChat["restrictionReason"] = get(data, "result.chats[0].restrictionReason", null);
      filteredfullChat["participantsCount"] = get(data, "result.fullChat.participantsCount", 0);
    }
    setApiResponse({
      filteredfullChat,
      fullChat: data
    });
  };

  useEffect(() => {
    if (ch) {
      handleSubmit({ preventDefault: () => {} });
    }
    // eslint-disable-next-line
  }, [ch]);

  const tableData = get(apiResponse, "fullChat.chatInfoLogs.channel.fullChatLogs", []);
  return (
    <main>
      <div className="content">
        <h1>Get full chat info</h1>
        <form>
          <Input id="channelName" name="channelName" label="ChannelName" value={channelName} onChange={setchannelName} />

          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
          >
            <div className="d-flex align-items-center">
              <button disabled={loading} className={`btn primary`} type="submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </form>
        <textarea
          style={{ width: "100%" }}
          id="textMessage"
          rows="3"
          name="textMessage"
          label="Text message"
          value={JSON.stringify(apiResponse.filteredfullChat)}
        />
        {tableData.length ? (
          <table className="table">
            <thead>
              <tr>
                <th>SNo</th>
                <th>Updated Date</th>
                <th>Sub</th>
                <th>PTS</th>
                <th>Restriction</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((log, index) => {
                return (
                  <tr key={`tassk-${index}`}>
                    <td>{index + 1}</td>
                    <td>{moment(log.date).format("h:mmA DD-MM-YYYY")}</td>
                    <td>{log.participantsCount}</td>
                    <td>{log.pts}</td>
                    <td>{(log.restrictionReason || []).map(el => `${el.reason}-${el.platform}`).join(", ")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div />
        )}
        <textarea
          style={{ width: "100%" }}
          id="channelList"
          rows="20"
          name="channelList"
          label=""
          value={JSON.stringify(apiResponse.fullChat?.result || {})}
        />
      </div>
      {loading && (
        <div className="d-flex loading-box">
          <div className="loading-div"></div>
          <div>Loading...</div>
        </div>
      )}
    </main>
  );
}
