import React, { useState, useEffect } from "react";
import APICaller from "../utils/APICaller";
import { useHistory, useLocation } from "react-router-dom";
import Input from "../components/Input";
import Select from "../components/Select";
import CategorySelector, { defaultCatState } from "../components/CategorySelector";

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { getCatFromLocalStorage } from "../utils/methods";

const defaultLinkOptions = [{ value: "terabox" }, { value: "vivdisk" }, { value: "mdisk" }, { value: "dood" }];

export default function DriveHelp() {
  const history = useHistory();
  const location = useLocation();
  const locationState = location?.state || {};

  const [linkOptions, setLinkOptions] = useState(defaultLinkOptions);

  const [groupId, setGroupId] = useState(locationState.groupInfo?.id || "");
  const [groupInfo, setGroupInfo] = useState(locationState.groupInfo || {});

  const [categoryState, setCategoryState] = useState(locationState.categoryState || [defaultCatState]);

  const [channelName, setChannelName] = useState(locationState.channelName || "primexmov");
  const [backupChannelLink, setBackupChannelLink] = useState(locationState.backupChannelLink || "");

  const [linkType, setLinkType] = useState(locationState.linkType || linkOptions[0].value);

  useEffect(() => {
    const isMainBot = getCatFromLocalStorage().isMainBot;
    if (isMainBot) {
      setLinkOptions([...defaultLinkOptions, { value: "ios-content" }]);
    }
  }, []);

  const [thumbActive, setThumbActive] = useState(!!locationState.thumbUrl || false);
  const [thumbUrl, setThumbUrl] = useState(
    locationState.thumbUrl || "https://drive.google.com/uc?export=view&id=1GK6SH3Kwgu-Nwr4ilQPyiKuk26tbZmxb"
  );
  const handleSetThumbUrl = el => {
    setThumbActive(el);
    if (el) {
      setCategoryState(categoryState.map(el => ({ ...el, isRealImage: false })));
    } else {
      setCategoryState(
        categoryState.map(el => {
          delete el.isRealImage;
          return { ...el };
        })
      );
    }
  };
  const [isCustomCname, setIsCustomCname] = useState(locationState.cname ? locationState.cname !== "v1" : false);
  const [cname, setCname] = useState(locationState.cname || "v1");

  // eslint-disable-next-line
  const [isEuOrgLink, setIsEuOrgLink] = useState(locationState.groupInfo ? locationState.isEuOrgLink : true);
  const [isNewMdisk, setIsNewMdisk] = useState(locationState.groupInfo ? locationState.isNewMdisk : false);

  // useCustomMessage
  const [useCustomMessage, setCustomMessage] = useState(locationState.useCustomMessage ? locationState.useCustomMessage : false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCategoryState = (newCat, index) => {
    const newCategoryState = [...categoryState];
    if (typeof index === "number") {
      newCategoryState[index] = newCat;
    } else {
      newCategoryState.push(newCat);
    }
    setCategoryState(newCategoryState);
  };

  const handleDeleteCatagory = index => {
    const newCategoryState = categoryState.filter((el, i) => i !== index);
    setCategoryState(newCategoryState);
  };

  const onSubmit = async e => {
    if (e) e.preventDefault();
    if (isEmpty(groupInfo)) {
      setError("Click on get info after passing groupId");
      return false;
    }
    setLoading(true);
    const params = {
      groupInfo,
      categoryState,
      channelName,
      backupChannelLink,
      linkType,
      isNewMdisk,
      isEuOrgLink,
      useCustomMessage
    };
    params.thumbUrl = thumbActive ? thumbUrl : "";
    params.cname = isCustomCname ? cname : "";

    console.log("sfsfsf", params);
    try {
      if (locationState._id) {
        if (params.groupInfo === locationState.groupInfo && params.groupInfo) delete params.groupInfo;
        if (params.channelName === locationState.channelName && params.channelName) delete params.channelName;
        if (params.backupChannelLink === locationState.backupChannelLink && params.backupChannelLink) delete params.backupChannelLink;
        if (params.linkType === locationState.linkType && params.linkType) delete params.linkType;
        if (thumbActive && params.thumbUrl === locationState.thumbUrl) delete params.thumbUrl;
        if (params.isNewMdisk === locationState.isNewMdisk) delete params.isNewMdisk;
        if (params.useCustomMessage === locationState.useCustomMessage) delete params.useCustomMessage;
        if (params.isEuOrgLink === locationState.isEuOrgLink) delete params.isEuOrgLink;
        if (isEqual(params.categoryState, locationState.categoryState)) delete params.categoryState;
        if (isEmpty(params)) {
          setLoading(false);
          return;
        }
        params._id = locationState._id;
        const { data } = await APICaller({
          method: "POST",
          reqUrl: "/api/v1/task/update",
          data: params
        });
        console.log("update response==", data);
        if (data) {
          history.push("/task");
        }
        setLoading(false);
      } else {
        const { data } = await APICaller({
          method: "POST",
          reqUrl: "/api/v1/task/add",
          data: params
        });
        console.log("api response===", data);
        if (data) {
          history.push("/task");
        }
        setLoading(false);
      }
    } catch (error) {
      console.log("api error", error);
      setLoading(false);
    }
  };

  return (
    <main>
      <div className="content">
        <h1>Gdrive token generator</h1>
        <form onSubmit={onSubmit}>
          <Select id="linkType" name="linkType" label="Link Type" options={linkOptions} value={linkType} onChange={setLinkType} required />
          <Input id="groupId" name="groupId" label="Group Id" value={groupId} onChange={setGroupId} required />
          <div className="d-flex" style={{ alignItems: "center" }}>
            <button
              disabled={loading}
              onClick={async () => {
                try {
                  setLoading(true);
                  const { data: telegramData = {} } = await APICaller({
                    method: "GET",
                    reqUrl: "/api/v1/task/telegramget",
                    data: {
                      url: `https://api.telegram.org/bot_bot_token_/getChat?chat_id=${groupId}`
                    }
                  });
                  console.log("api response===", telegramData);
                  setGroupInfo(telegramData.data.result);
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                  setGroupInfo({});
                }
              }}
            >
              {loading ? "Loading..." : "Get Chat Info"}
            </button>
            <div style={{ flex: 1 }}>
              <Input id="groupName" name="groupName" label="" value={groupInfo.title} disabled />
            </div>
          </div>
          <Input
            id="channelName"
            name="channelName"
            label="Channel Name(without @)"
            value={channelName}
            onChange={setChannelName}
            required
          />
          <Input
            id="backupChannelLink"
            name="backupChannelLink"
            label="Backup Channel Link"
            value={backupChannelLink}
            onChange={setBackupChannelLink}
          />
          <div style={{ paddingBottom: 20 }}>
            <input type="checkbox" id="isNewMdisk" name="isNewMdisk" checked={isNewMdisk} onChange={() => setIsNewMdisk(!isNewMdisk)} />
            <label htmlFor="isNewMdisk">Convert New mdisk links by bot</label>
          </div>
          <div style={{ paddingBottom: 20 }}>
            <input
              type="checkbox"
              id="useCustomMessage"
              name="useCustomMessage"
              checked={useCustomMessage}
              onChange={() => setCustomMessage(!useCustomMessage)}
            />
            <label htmlFor="useCustomMessage">Use bot Custom Message with header+footer</label>
          </div>

          <div style={{ paddingBottom: 20 }}>
            <input
              type="checkbox"
              id="isEuOrgLink"
              name="isEuOrgLink"
              checked={isEuOrgLink}
              onChange={() => setIsEuOrgLink(!isEuOrgLink)}
            />
            <label htmlFor="isEuOrgLink">Get mdisk.eu.org links</label>
          </div>
          <div style={{ paddingBottom: thumbActive ? 5 : 20 }}>
            <input
              type="checkbox"
              id="thumbActive"
              name="thumbActive"
              checked={thumbActive}
              onChange={() => handleSetThumbUrl(!thumbActive)}
            />
            <label htmlFor="thumbActive">Add Thumb image to message</label>
          </div>
          {thumbActive && <Input id="thumbUrl" name="thumbUrl" label="" value={thumbUrl} onChange={setThumbUrl} />}
          <div style={{ paddingBottom: isCustomCname ? 5 : 20 }}>
            <input
              type="checkbox"
              id="isCustomCname"
              name="isCustomCname"
              checked={isCustomCname}
              onChange={() => setIsCustomCname(!isCustomCname)}
            />
            <label htmlFor="isCustomCname">Use Custom cname</label>
          </div>
          {isCustomCname && <Input id="cname" name="cname" label="" value={cname} onChange={setCname} />}
          {categoryState.map((el, index) => (
            <CategorySelector
              key={index}
              index={index}
              defaultState={el}
              handleCategoryState={handleCategoryState}
              isLastElement={categoryState.length - 1 === index}
              handleDeleteCatagory={handleDeleteCatagory}
              isThumbActive={thumbActive}
            />
          ))}
          {error && <div style={{ color: "red" }}>{error}</div>}
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
          >
            <button disabled={loading} className={`btn primary${loading ? " loading" : ""}`} type="submit">
              {locationState._id ? "Update Task" : "Insert Task"}
            </button>

            {locationState._id && (
              <button
                disabled={loading}
                className="btn danger"
                onClick={async () => {
                  setLoading(true);
                  try {
                    const params = { isDeleted: true };
                    params._id = locationState._id;
                    const { data } = await APICaller({
                      method: "POST",
                      reqUrl: "/api/v1/task/update",
                      data: params
                    });
                    console.log("Delete response==", data);
                    if (data) {
                      history.push("/task");
                    }
                    setLoading(false);
                  } catch (error) {
                    setLoading(false);
                  }
                }}
              >
                Delete
              </button>
            )}
          </div>
        </form>
      </div>
    </main>
  );
}
