import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Select from "../components/Select";
import { getCatFromLocalStorage } from "../utils/methods";

let catOptions = [
  { label: "1Webseries", value: "1" },
  { label: "2English", value: "2" },
  { label: "3English Premium", value: "3" },
  { label: "4Desi", value: "4" },
  { label: "5English Bulk", value: "5" },
  { label: "6Tango&onlyfans", value: "6" },
  { label: "7-18+", value: "7" }
];

export const defaultCatState = {
  category: catOptions[3].value,
  size: "20",
  page: "0",
  pageIncrementor: "2",
  isRealImage: false
};

const CategorySelector = props => {
  const { defaultState, handleCategoryState, index, isLastElement, handleDeleteCatagory, isThumbActive = false } = props;

  const tempObj = getCatFromLocalStorage();
  catOptions = tempObj?.categories && tempObj?.categories.length ? tempObj?.categories : catOptions;
  const [category, setCategory] = useState(defaultState.category || catOptions[0].value);
  const [size, setSize] = useState(defaultState.size || "40");
  const [page, setPage] = useState(defaultState.page || "0");
  const [pageIncrementor, setPageIncrementor] = useState(defaultState.pageIncrementor || "2");
  const [isRealImage, setIsRealImage] = useState(!!defaultState.isRealImage);

  useEffect(() => {
    handleCategoryState({ category, size, page, pageIncrementor, isRealImage }, index);
    // eslint-disable-next-line
  }, [category, size, page, pageIncrementor, isRealImage]);

  return (
    <div className="category-select">
      <Select id="category" name="category" label="Category" options={catOptions} value={category} onChange={setCategory} required />
      <Input id="size" max="100" name="size" label="Size(Number of messages)" value={size} onChange={setSize} required type="number" />
      <Input id="page" name="page" label="Send Page" value={page} onChange={setPage} required type="number" />
      <Input
        id="pageIncrementor"
        name="pageIncrementor"
        label="Page Increment Factor"
        value={pageIncrementor}
        onChange={setPageIncrementor}
        required
        type="number"
      />
      {isThumbActive && (
        <div style={{ paddingBottom: 20 }}>
          <input type="checkbox" id="isRealImage" name="isRealImage" checked={isRealImage} onChange={() => setIsRealImage(!isRealImage)} />
          <label htmlFor="isRealImage">Send real images</label>
        </div>
      )}

      <div className="category-select-actions">
        {(!index ? !isLastElement : true) && (
          <button className={`btn danger `} onClick={handleDeleteCatagory.bind(this, index)} type="button">
            Delete
          </button>
        )}

        {isLastElement && (
          <button className={`btn outline `} onClick={handleCategoryState.bind(this, defaultCatState)} type="button">
            Add More Category
          </button>
        )}
      </div>
    </div>
  );
};
export default CategorySelector;
