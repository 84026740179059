import React from "react";
import isEmpty from "lodash/isEmpty";
import clamp from "lodash/clamp";

import Progressbar from "./ProgressBar";
const progressHeight = 16;

const BotState = props => {
  const { botState = {} } = props;
  const { total = 0, current = 0, nextData = 0 } = botState;

  return (
    <div className="bot-state">
      {isEmpty(botState) ? (
        <div>No Active task</div>
      ) : (
        <>
          {total ? (
            <>
              <div>Current Queue</div>
              <Progressbar
                bgcolor="#ff00ff"
                progress={clamp(Math.trunc((current * 100) / total), 0, 100)}
                progressText={`${current}/${total}`}
                height={progressHeight}
              />
            </>
          ) : null}
          {nextData ? (
            <>
              <div>Next Queue</div>
              <Progressbar bgcolor="orange" progress="0" progressText={`${0}/${nextData}`} height={progressHeight} />{" "}
            </>
          ) : null}
        </>
      )}
    </div>
  );
};
export default BotState;
