import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import orderBy from "lodash/orderBy";
import { splitArray } from "../utils";

const getTimeStr = date => {
  const dateObj = new Date(date);
  return `${dateObj.toLocaleTimeString()} ${dateObj.toLocaleDateString()}`;
};

export default function JoinRequests() {
  const [data, setdata] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setfilter] = useState("");
  const [loadingText, setLoadingText] = useState("Loading...");

  // const [pageLimit, setPageLimit] = useState("100");

  // ['asc', 'desc']
  const [sort, setSort] = useState({ by: "", order: "asc" });

  const { joinRequests: allJoinRequests = [] } = data;
  // filter ? allJoinRequests.filter(e => moment().diff(moment(e.created), "days") >= parseInt(filter)) :
  const filterTasks = allJoinRequests;
  const tasks = orderBy(filterTasks, sort.by, sort.order);

  const handleSort = col => {
    const temp = sort.by === col && sort.order === "asc" ? "desc" : "asc";
    setSort({ by: col, order: temp });
  };

  const getData = async () => {
    setLoading(true);
    const dayfilter = parseInt(filter);
    const newDate = moment().add("days", -dayfilter);
    const getDateFilter = filter ? { created: newDate.format("YYYY-MM-DD") } : {};
    const { data: resData = {} } = await axios.post("/api/v1/chatjoin/all", { action: "list", data: getDateFilter });
    setdata(resData.data);
    setLoading(false);
  };

  const handleFilters = el => {
    setfilter(el);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [filter]);

  const onClickApproveRequest = async (endpoint = "/approve") => {
    try {
      setLoading(true);
      const selectedTask = tasks.filter(e => selectedItems.includes(e._id));
      const [finalArr, pageSize] = splitArray(selectedTask);

      for (const iterator of finalArr) {
        try {
          const activeIndex = finalArr.indexOf(iterator);
          setLoadingText(`Accepting request ${activeIndex * pageSize}/${selectedTask.length}`);
          const { data: approveData = {} } = await axios.post(`/api/v1/chatjoin${endpoint}`, {
            requests: iterator
          });
          console.log("Approve response===", approveData);
        } catch (error) {
          // setLoadingText(`Starting bot on ${1 * pageSize}/${selectedTask.length}`);
        }
      }
      setLoading(false);
      setLoadingText("Loading...");
      getData();
    } catch (error) {
      setLoading(false);
      getData();
    }
  };

  return (
    <main>
      <div className="content">
        <div className="d-flex">
          <div className="d-flex flex-1">
            <h1>Requests List</h1>
            <button style={{ marginLeft: 20 }} className="danger" onClick={getData}>
              Refresh
            </button>
          </div>
          {!!selectedItems.length && (
            <>
              <button disabled={loading} style={{ marginRight: 5 }} onClick={() => onClickApproveRequest()}>
                {`Approve ${selectedItems.length} requests`}
              </button>
              <button disabled={loading} onClick={() => onClickApproveRequest("/approveandclear")}>
                {`ApproveClear ${selectedItems.length} requests`}
              </button>
            </>
          )}
        </div>
        <div className="d-flex align-center" style={{ padding: "15px 0px" }}>
          <div>Filters: </div>
          <button
            className={filter === "1DAY" ? "" : "outline"}
            onClick={() => {
              setSelectedItems([]);
              handleFilters(filter === "1DAY" ? "" : "1DAY");
            }}
          >
            1 day ago
          </button>
          <button
            className={filter === "2DAY" ? "" : "outline"}
            onClick={() => {
              setSelectedItems([]);
              handleFilters(filter === "2DAY" ? "" : "2DAY");
            }}
          >
            2 day ago
          </button>
        </div>
        <table className="task">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectedItems.length === tasks.length}
                  onChange={e => {
                    setSelectedItems(e.target.checked ? tasks.map(e => e._id) : []);
                  }}
                />
              </th>
              <th>S.No</th>
              <th onClick={() => handleSort("from.id")}>User Id</th>
              <th onClick={() => handleSort("from.first_name")}>First Name</th>
              <th onClick={() => handleSort("chat.title")}>Chat</th>
              <th onClick={() => handleSort("created")}>Created</th>
              <th onClick={() => handleSort("status")}>Status</th>
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks.map((el, index) => (
                <tr key={`tassk-${index}`}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(el._id)}
                      onChange={e => {
                        setSelectedItems(e.target.checked ? [...selectedItems, el._id] : selectedItems.filter(e => e !== el._id));
                      }}
                    />
                  </td>
                  <td>{index + 1}</td>
                  <td>{el.from.id}</td>
                  <td>{el.from.first_name}</td>
                  <td>{el.chat.title}</td>
                  <td>{el.created && getTimeStr(el.created)}</td>
                  <td>
                    <>
                      <button
                        disabled={loading}
                        onClick={async () => {
                          try {
                            setLoading(true);
                            const { data: approveData = {} } = await axios.post("/api/v1/chatjoin/reject", {
                              requests: [el]
                            });
                            console.log("reject response===", approveData);
                            setLoading(false);
                          } catch (error) {
                            setLoading(false);
                          }
                        }}
                      >
                        Reject
                      </button>
                      <button
                        className="btn"
                        disabled={loading}
                        onClick={async () => {
                          try {
                            setLoading(true);
                            const { data: approveData = {} } = await axios.post("/api/v1/chatjoin/approve", {
                              requests: [el]
                            });
                            console.log("Approve response===", approveData);
                            setLoading(false);
                          } catch (error) {
                            setLoading(false);
                          }
                        }}
                      >
                        Approve
                      </button>
                      {/* <div>{el.status === "active" ? "" : el.status}</div> */}
                    </>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* <div className="pagination">
          <select onSelect={el => console.log(el)} value={pageLimit}>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="500">500</option>
          </select>
        </div> */}
        {loading && (
          <div className="d-flex loading-box">
            <div className="loading-div"></div>
            <div>{loadingText}</div>
          </div>
        )}
      </div>
    </main>
  );
}
