import React, { useState } from "react";
import Input from "../components/Input";
import Select from "../components/Select";

const linkOptions = [{ value: "mdisk" }, { value: "dood" }, { value: "vivdisk" }, { value: "terabox" }];

export default function DriveHelp() {
  const [linkType, setLinkType] = useState(linkOptions[0].value);
  const [sucessMsg, setSucessMsg] = useState("");

  const [thumbUrl, setThumbUrl] = useState("https://drive.google.com/uc?export=view&id=1GK6SH3Kwgu-Nwr4ilQPyiKuk26tbZmxb");
  const [textMessage, setTextMessage] =
    useState(`Friends Now a days Telegram Is Banning Many Movies Channels. Our Channels Also Got Banned. So, Please Join This Backup Channel For Future Update.

If Telegram Ban Our Channel We Will Give You New Channel Link On This Channel.
  
  
दोस्तों आजकल टेलीग्राम कई चैनलों पर प्रतिबंध लगा रहा है। हमारे कई चैनलों पर भी प्रतिबंध लगा दिया। इसलिए, भविष्य के अपडेट के लिए कृपया इस बैकअप चैनल से जुड़ें। 
      
यदि टेलीग्राम द्वारा हमारे चैनल पर प्रतिबंध लगाया गया तो हम आपको इस चैनल पर नए चैनल लिंक देंगे ।
      
👇👇👇👇👇👇👇👇👇👇👇👇   
🔋🔋
Channel:-
_CHANNEL_
_CHANNEL_
_CHANNEL_
🔋🔋🔋`);

  return (
    <main>
      <div className="content">
        <h1>Gdrive token generator</h1>
        <form>
          <Select id="linkType" name="linkType" label="Link Type" options={linkOptions} value={linkType} onChange={setLinkType} required />

          <Input id="thumbUrl" name="thumbUrl" label="ThumbUrl" value={thumbUrl} onChange={setThumbUrl} />

          <textarea
            style={{ width: "100%" }}
            id="textMessage"
            rows="20"
            name="textMessage"
            label=""
            value={textMessage}
            onChange={e => setTextMessage(e.target.value)}
          />

          <div
            className="d-flex"
            style={{
              justifyContent: "space-between"
            }}
          >
            <div className="d-flex align-items-center">
              <button
                disabled={!textMessage || sucessMsg}
                className={`btn primary`}
                type="submit"
                onClick={e => {
                  e.preventDefault();
                  const encodThumbUrl = encodeURIComponent(thumbUrl);
                  const encodesMessage = encodeURIComponent(textMessage);
                  const finalLink = `${window.location.origin}/api/v1/task/backupMessage?linkType=${linkType}${
                    thumbUrl ? "&thumbUrl=" + encodThumbUrl : ""
                  }&text=${encodesMessage}`;
                  console.log(finalLink);
                  navigator.clipboard.writeText(finalLink);
                  setSucessMsg("Copied the link");
                  setInterval(() => {
                    setSucessMsg("");
                  }, 6000);
                }}
              >
                Copy Link
              </button>
              {sucessMsg && <div style={{ color: "green", fontWeight: "bold" }}>{sucessMsg}</div>}
            </div>
          </div>
        </form>
      </div>
    </main>
  );
}
