import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import orderBy from "lodash/orderBy";
import get from "lodash/get";
import BotState from "../components/BotState";
import { splitArray } from "../utils";
import isEmpty from "lodash/isEmpty";
import useTabState from "../hook/useTabState";
import APICaller from "../utils/APICaller";
import { getCatFromLocalStorage, setCatFromLocalStorage } from "../utils/methods";

const getTimeStr = date => {
  const dateObj = new Date(date);
  return `${dateObj.toLocaleTimeString()} ${dateObj.toLocaleDateString()}`;
};

export default function Task() {
  const [data, setdata] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [botState, setBotState] = useState({});
  const { isActive } = useTabState();

  const [categories, setCategories] = useState([]);
  const [catLoading, setCatLoading] = useState(false);
  const catObj = categories.reduce((ac, cu) => ({ ...ac, [cu.value]: cu.label }), {});

  const [selectedItems, setSelectedItems] = useState([]);
  const [filters, setfilters] = useState([]);

  // ['asc', 'desc']
  const [sort, setSort] = useState({ by: "", order: "asc" });

  const { tasks: allTask = [] } = data;
  let filterTasks = filters.length ? allTask.filter(e => filters.includes(e.linkType) || filters.includes(e.status)) : allTask;
  filterTasks = filterTasks.map(el => ({
    ...el,
    fullChat: { pts: 0, ...el.fullChat },
    size: el.categoryState.reduce((ac, e) => ac + +e.size, 0),
    flags: `${el.isEuOrgLink ? "eu.org" : ""}\n${el.isNewMdisk ? "Newlink" : ""}\n${el.thumbUrl ? "Thumb" : ""}\n${
      el.useCustomMessage ? "HF_MSG" : ""
    }`
  }));
  const tasks = orderBy(filterTasks, sort.by, sort.order);

  const handleSort = col => {
    const temp = sort.by === col && sort.order === "asc" ? "desc" : "asc";
    setSort({ by: col, order: temp });
  };

  const getData = async () => {
    setLoading(true);
    const { data: resData = {} } = await APICaller({
      method: "GET",
      reqUrl: "/api/v1/task/list"
    });
    setdata(resData);
    setLoading(false);
  };

  const getProcesStateData = async () => {
    const { data: resData = {} } = await APICaller({
      method: "GET",
      reqUrl: "/api/v1/task/processStats"
    });
    setBotState(resData);
  };

  const getCategoryData = async () => {
    setCategories(getCatFromLocalStorage().categories);
    setCatLoading(true);
    const { data: resData = {} } = await APICaller({
      method: "GET",
      reqUrl: "/api/v1/category/list"
    });
    setCategories(resData.categories || []);
    setCatFromLocalStorage(resData);
    setCatLoading(false);
  };

  useEffect(() => {
    getCategoryData();
    getData();
    getProcesStateData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isActive && !loading) {
      const refreshTime = isEmpty(botState) ? 30 * 1000 : 10 * 1000;
      const timer = setTimeout(getProcesStateData, refreshTime);
      return () => clearTimeout(timer);
    }
  }, [botState, isActive, loading]);

  const updateChannelInfo = async (numberOfTimes = 1) => {
    try {
      setLoading(true);
      const { data } = await APICaller({
        method: "GET",
        reqUrl: "/api/v1/task/getMetaData"
      });
      setLoading(false);
      console.log("tasks response===", data);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const startTask = async (numberOfTimes = 1) => {
    try {
      setLoading(true);
      let selectedTask = tasks.filter(e => selectedItems.includes(e._id));
      selectedTask = numberOfTimes !== 1 ? selectedTask.map(el => ({ ...el, numberOfTimes })) : selectedTask;
      const [finalArr, pageSize] = splitArray(selectedTask);
      for (const iterator of finalArr) {
        try {
          const activeIndex = finalArr.indexOf(iterator);
          setLoadingText(`Starting bot on ${activeIndex * pageSize}/${selectedTask.length}`);
          const { data } = await APICaller({
            method: "POST",
            reqUrl: "/api/v1/task/start",
            data: iterator
          });
          console.log("tasks response===", data);
        } catch (error) {
          // setLoadingText(`Starting bot on ${1 * pageSize}/${selectedTask.length}`);
        }
      }
      setLoading(false);
      setLoadingText("Loading...");
      getData();
    } catch (error) {
      setLoading(false);
      getData();
    }
  };

  const selectFilters = ({ typeLink, clearAll = false }) => {
    setSelectedItems([]);
    console.log("typeLink, clearAll", typeLink, clearAll);
    const isSelected = filters.includes(typeLink);
    if (clearAll) {
      setfilters(isSelected ? [] : [typeLink]);
    } else if (isSelected) {
      setfilters(filters.filter(el => el !== typeLink));
    } else {
      setfilters([...filters, typeLink]);
    }
  };

  console.log("filters", filters);

  const renderRistrictionSpan = el => {
    const restrictionReason = get(el, "fullChat.restrictionReason");
    const text = restrictionReason ? restrictionReason.map(e => `${e.reason}-${e.platform}`).join("\n") : "";
    return <span>{"\n" + text}</span>;
  };
  return (
    <main>
      <div className="content">
        <div className="d-flex">
          <div className="d-flex flex-1">
            <h1>Tasks List</h1>
            <button style={{ marginLeft: 20 }} className="danger" onClick={getData}>
              Refresh
            </button>
          </div>
          {!!selectedItems.length && (
            <button disabled={loading || botState?.isQueueRunning} onClick={startTask.bind(this, 1)}>
              {`Send ${selectedItems.length} chats`}
            </button>
          )}
          {!!selectedItems.length && (
            <button disabled={loading || botState?.isQueueRunning} onClick={startTask.bind(this, 2)}>
              {`2xSend ${selectedItems.length} chats`}
            </button>
          )}
          <button onClick={() => history.push("/task/upsert")}>Add</button>
          <button disabled={loading || botState?.isQueueRunning} onClick={updateChannelInfo}>
            Update CInfo
          </button>
        </div>
        <BotState botState={botState} />
        <div className="d-flex align-center" style={{ padding: "15px 0px" }}>
          <div>Filters: </div>
          <button className={filters.includes("mdisk") ? "" : "outline"} onClick={selectFilters.bind(this, { typeLink: "mdisk" })}>
            Mdisk
          </button>
          <button className={filters.includes("vivdisk") ? "" : "outline"} onClick={selectFilters.bind(this, { typeLink: "vivdisk" })}>
            Vivdisk
          </button>
          <button className={filters.includes("terabox") ? "" : "outline"} onClick={selectFilters.bind(this, { typeLink: "terabox" })}>
            Terabox
          </button>
          <button
            className={filters.includes("processing") ? "" : "outline"}
            onClick={selectFilters.bind(this, { typeLink: "processing", clearAll: true })}
          >
            processing
          </button>
        </div>
        <table className="task">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectedItems.length === tasks.length}
                  onChange={e => {
                    setSelectedItems(e.target.checked ? tasks.map(e => e._id) : []);
                  }}
                />
              </th>
              <th>S.No</th>
              <th onClick={() => handleSort("groupInfo.title")}>Group Name</th>
              <th onClick={() => handleSort("membersCount")}>S count</th>
              <th onClick={() => handleSort("fullChat.pts")}>pts</th>
              <th onClick={() => handleSort("category")}>Category</th>
              <th onClick={() => handleSort("size")}>Size</th>
              <th onClick={() => handleSort("page")}>Send Page</th>
              <th onClick={() => handleSort("pageIncrementor")}>Page Incr</th>
              <th onClick={() => handleSort("channelName")}>PCName</th>
              <th onClick={() => handleSort("linkType")}>link Type</th>
              <th onClick={() => handleSort("cname")}>Cname</th>
              <th onClick={() => handleSort("flags")}>Flags</th>
              <th onClick={() => handleSort("lastExecuted")}>Last Executed</th>
              <th onClick={() => handleSort("status")}>Status</th>
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks.map((el, index) => (
                <tr key={`tassk-${index}`}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedItems.includes(el._id)}
                      onChange={e => {
                        setSelectedItems(e.target.checked ? [...selectedItems, el._id] : selectedItems.filter(e => e !== el._id));
                      }}
                    />
                  </td>
                  <td>{index + 1}</td>
                  <td
                    className={`cursor-pointer text-primary ${el?.groupInfo?.username ? "public-channel" : ""}`}
                    onClick={() => {
                      window.open(el?.groupInfo?.invite_link.replace("t.me", "telegram.dog"), "_blank");
                    }}
                  >{`${el?.groupInfo?.title} (${el?.groupInfo?.id?.toString().slice(4)}) ${el?.groupInfo?.username || ""} \n`}</td>
                  <td>{el.membersCount || ""}</td>
                  <td
                    className="cursor-pointer"
                    onClick={() => {
                      window.open(`/getfullchat/${el?.groupInfo?.id?.toString()}`, "_blank");
                    }}
                  >
                    {el?.fullChat?.pts || 0}
                    {renderRistrictionSpan(el)}
                  </td>
                  <td>{el.categoryState.map(e => `${catObj[e.category]} \n`)}</td>
                  <td>{el.categoryState.reduce((ac, e) => ac + +e.size, 0)}</td>
                  <td>
                    {el.categoryState.map(e => (
                      <div>{e.page}</div>
                    ))}
                  </td>
                  <td>
                    {el.categoryState.map(e => (
                      <div>{e.pageIncrementor}</div>
                    ))}
                  </td>
                  <td>{el.channelName}</td>
                  <td>{el.linkType}</td>
                  <td>{el.cname}</td>
                  <td>{el.flags}</td>
                  <td>{el.lastExecuted && getTimeStr(el.lastExecuted)}</td>
                  <td>
                    <>
                      {/* <button
                          disabled={loading}
                          onClick={async () => {
                            try {
                              setLoading(true);
                              const { data } = await axios.post("/api/v1/task/start", [el]);
                              console.log("start response===", data);
                              setLoading(false);
                            } catch (error) {
                              setLoading(false);
                            }
                          }}
                        >
                          Start
                        </button> */}
                      <button
                        className="btn icon-only"
                        onClick={() =>
                          history.push({
                            pathname: "/task/upsert",
                            state: el
                          })
                        }
                      >
                        Edit
                      </button>
                      <div>{el.status === "active" ? "" : el.status}</div>
                    </>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {(loading || catLoading) && (
          <div className="d-flex loading-box">
            <div className="loading-div"></div>
            <div>{loadingText}</div>
          </div>
        )}
      </div>
    </main>
  );
}
